import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import MenuCard01 from 'components/Software/Windows/InstarVision/Record/instarVisionMenuCards01';
import MenuCard02 from 'components/Software/Windows/InstarVision/Record/instarVisionMenuCards02';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Record/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "The InstarVision Surveillance Center offers you a variety of options to record events - program a scheduled video recording, trigger video recordings, FTP uploads and Email notifications by motion detection or use the software to create a series of photos - that can later be joined into a timelapse video.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Record/' locationFR='/fr/Software/Windows/InstarVision/Record/' crumbLabel="Record" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQI143HsQ6CMBhF4f9V9Pkc2DSwO9A61bAQJngVF9YONPEBjCsFSWnIMRHjaLjJl9wjLAEmvwojvPr1E9k6YwynNIM4Icp6tB3Qbka7+KPcjOrCX3kXuNwXsrPmmBzIrUekeiLlA6l7pPFI/dVsNbC/3tgV7aff3kvA79KvXEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/e4706/Banner_en-InstarVision2-0_Record.avif 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/d1af7/Banner_en-InstarVision2-0_Record.avif 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/7f308/Banner_en-InstarVision2-0_Record.avif 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/e1c99/Banner_en-InstarVision2-0_Record.avif 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/76ea5/Banner_en-InstarVision2-0_Record.avif 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/f696c/Banner_en-InstarVision2-0_Record.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/a0b58/Banner_en-InstarVision2-0_Record.webp 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/bc10c/Banner_en-InstarVision2-0_Record.webp 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/966d8/Banner_en-InstarVision2-0_Record.webp 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/445df/Banner_en-InstarVision2-0_Record.webp 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/78de1/Banner_en-InstarVision2-0_Record.webp 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/882b9/Banner_en-InstarVision2-0_Record.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/81c8e/Banner_en-InstarVision2-0_Record.png 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/08a84/Banner_en-InstarVision2-0_Record.png 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/b1001/Banner_en-InstarVision2-0_Record.png 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/161ec/Banner_en-InstarVision2-0_Record.png 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---record",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---record",
        "aria-label": "side navigation   record permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Record`}</h2>
    <p>{`The InstarVision Surveillance Center offers you a variety of options to record events - program a scheduled video recording, trigger video recordings, FTP uploads and Email notifications by motion detection or use the software to create a series of photos - that can later be joined into a timelapse video*.`}</p>
    <p><em parentName="p">{`*needs a third-party software`}</em></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <MenuCard01 mdxType="MenuCard01" />
    <EuiSpacer size="s" mdxType="EuiSpacer" />
    <MenuCard02 mdxType="MenuCard02" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      